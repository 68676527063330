<template>
  <section class="container">
    <v-chip
      class="ma-2"
      label
      text-color="primary"
      color="primary"
      outlined
      right
      to="/components/concept-tool"
      @click="alert = !alert"
    >
      {{ $t('message.concept_tool') }}
    </v-chip>
    <twitter-tab v-if="dataFetched" :items="twitterItems" />
  </section>
</template>

<script>
  import TwitterTab from '../components/TwitterTab.vue';

  import axios from 'axios';

  export default {
    metaInfo: {
      title: 'Concept',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          name: 'keywords',
          content:
            'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden',
        },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://ff14eden.work' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        {
          name: 'description',
          content:
            'FFXIV Housing EdenはFFXIV(ファイナルファンタジー14)でのハウジング情報を配信するサイトです。',
        },
        {
          property: 'og:image',
          content: 'https://ff14eden.work/twitter_card.jpg?3',
        },
      ],
    },

    components: {
      TwitterTab,
    },
    data() {
      return {
        tabid: null, // デフォルトのタブを選択します。ここを変えることでデフォルトタブを変更できます。
        twitterItems: [],
        dataFetched: false, // データの取得が完了したかどうかを示すフラグ
      };
    },
    watch: {
      // '$route.params'の変更を監視しています
      '$route.params': {
        immediate: true,
        handler(newParams) {
          this.tabid = Number(newParams.tabid) || 0;
        },
      },
    },

    created: async function () {
      try {
        // 各サービスのデータを取得します
        await Promise.all([this.fetchData('twitter')]);
        this.dataFetched = true; // データの取得が完了したのでフラグをtrueに設定
      } catch (e) {
        console.error('データの取得に失敗しました:', e);
      }
    },
    methods: {
      // 各サービスのデータを取得するメソッドを共通化
      async fetchData(service) {
        try {
          const response = await axios.get(
            `../../../PopularFF14HousingTweets/PopularFF14HousingTweets.json?timestamp=${new Date().getTime()}`
          );

          // "#HousingEden" を含むデータを抽出
          const filteredData = response.data.filter((item) => {
            const tags = item.tags.map((tag) => tag.toLowerCase());
            return tags.includes('#housingedenconcept');
          });

          // timestampを基に降順に並び替え
          this[`${service}Items`] = filteredData.sort(
            (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
          );
        } catch (e) {
          if (e.response && e.response.status === 404) {
            console.log(`${service}のデータが存在しません。`);
            this[`${service}Items`] = [];
          } else {
            // 404エラー以外のエラーをキャッチ
            throw e;
          }
        }
      },
    },
  };
</script>
